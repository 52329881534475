(function($) {
  
  $(".menu-toggler").click(function() {
    console.log("klik");
    $(".banner").attr(
      "data-state",
      $(".banner").attr("data-state") == "collapsed" ? "expanded" : "collapsed"
    );
  });

})(jQuery);